import React, { useEffect, useState, useRef } from "react";

const Testing2 = () => {
  const [messages, setMessages] = useState([]);
  const wsRef = useRef(null);

  useEffect(() => {
    const connectWebSocket = async () => {
      wsRef.current = new WebSocket(
        `${process.env.REACT_APP_BACKEND_API_BASE_URL_ORCHESTRATE_WS}orchestrate/families/ws`,
      );

      wsRef.current.onopen = () => {
        console.log("WebSocket connected");
      };

      wsRef.current.onmessage = (event) => {
        setMessages((prev) => [...prev, event.data]);
      };

      wsRef.current.onclose = () => {
        console.log("WebSocket closed");
      };

      wsRef.current.onerror = (error) => {
        console.error("WebSocket error:", error);
      };
    };

    connectWebSocket().then("back");

    // Cleanup on unmount
    return () => {
      if (wsRef.current) {
        wsRef.current.close();
      }
    };
  }, []);

  const sendMessage = () => {
    if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
      wsRef.current.send("Hello from React client!");
    }
  };

  return (
    <div style={{ margin: "1rem" }}>
      <h1>Hello World with Auth0 + FastAPI WebSockets</h1>

      <button onClick={sendMessage}>Send Message via WebSocket</button>

      <ul>
        {messages.map((msg, index) => (
          <li key={index}>{msg}</li>
        ))}
      </ul>
    </div>
  );
};

export default Testing2;
